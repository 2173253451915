import { ReactNode } from 'react';

export interface ClassNameOnlyProps {
    className?: string;
}
export type CommonProps = ClassNameOnlyProps & {
    children: React.ReactNode;
};

export enum ModuleTypes {
    KEY_TAKEAWAYS = 'keyTakeaways',
    COMPANY_ANALYSIS = 'companyAnalysis',
    COMPANY_EVALUATION_ANALYSIS = 'companyEvaluationAnalysis',
    PEER_FUNDAMENTALS = 'peer_fundamentals',
    PRICE_HISTORY = 'price_history',
    ASK_A_QUESTION = 'ask_a_question',
    DOCUMENTS = 'documents',
    FILTERS = 'filters',
    EVALUATION_SUMMARY = 'evaluation_summary',
    FUNDAMENTALS = 'fundamentals',
    COMPARATIVE_ANALYSIS = 'comparative_analysis',
    PUBLIC_FILINGS = 'public_filings',
    EARNINGS_REPORTS = 'earnings_reports',
    FINANCIAL_STATEMENTS = 'financial_statements',
    COMPANY_PERFORMANCE = 'company_performance',
    DESCRIPTION = 'description',
    METRICS = 'metrics',
    NEWS = 'news',
    SSE = 'companySSE',
    INSIGHTS = 'companyInsights',
    EARNINGS_CALL = 'companyEarningsCall',
    FILINGS = 'companySecFilings',
    PEERS = 'companyPeers',
    FINANCIALS = 'companyFinancials',
    VALUATION = 'companyValuation',
    RESEARCH_ANALYSIS = 'researchAnalysis',
    SUBCATEGORIES = 'subcategories',
    DEEP_DIVE = 'deepDive',
    MATCHES = 'matches',
}

export type JSXElementProps = {
    className?: string;
    id?: string;
    key?: React.Key;
    children?: ReactNode;
};

// Define a union of common prop types
export type PropValue =
    | string
    | number
    | boolean
    | null
    | undefined
    | object
    | (() => void)
    | ((event: React.SyntheticEvent) => void)
    | ReadonlyArray<PropValue>
    | ReactNode
    | { [key: string]: PropValue };
